import * as React from "react";
import { graphql, Link } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/Hero";
import TestimonialsSection from "../components/TestimonialsSection";
import ServicesSection from "../components/ServicesSection";
import TeamCTA from "../components/TeamCTA";
import ContactSection from "../components/ContactSection";

const IndexPage = ({
	data: {
		hero,
		menuItems,
		testimonialSection,
		services,
		cta,
		settings,
		contactUs,
	},
}) => {
	const heroData = hero.nodes[0];
	const heroTitleOne = heroData.titleOne;
	const heroTitleTwo = heroData.titleTwo;
	const heroSubtitle = heroData.subtitle;
	const primaryButton = heroData.primaryButton;
	const secondaryButton = heroData.secondaryButton;
	const heroTitle = [heroTitleOne, heroTitleTwo];
	const menu = menuItems.nodes;
	const image = heroData.image;
	const allSettings = settings.nodes[0];

	const logoImg = allSettings.logo;
	const fbUrl = allSettings.facebookUrl;
	const fbLink = allSettings.facebook;
	const contactEmail = allSettings.contactEmail;
	const contactNum = allSettings.contactNumber;

	const testimonialsData = testimonialSection.nodes[0];
	const {
		testimonialAuthor,
		testimonialText,
		testimonialsTitle,
		testimonialImage,
		_rawBody: testimonialsBody,
	} = testimonialsData;
	const allServices = services.nodes;
	const { ctaTitle, ctaBody, ctaImage, ctaButton } = cta.nodes[0];
	const {
		description: contactUsDescription,
		title: contactUsTitle,
	} = contactUs.nodes[0];

	return (
		<>
			<SEO title="VGSS" />
			<Layout title="VGSS">
				<header>
					<Hero
						title={heroTitle}
						subtitle={heroSubtitle}
						menuItems={menu}
						primaryButton={primaryButton}
						secondaryButton={secondaryButton}
						image={image}
						logo={logoImg}
						contactNumber={contactNum}
					/>
				</header>
				<main id="main" tabIndex="-1">
				<TestimonialsSection
					title={testimonialsTitle}
					body={testimonialsBody}
					testimonial={testimonialText}
					author={testimonialAuthor}
					authorImage={testimonialImage}
				/>
				<ServicesSection services={allServices} />
				<TeamCTA
					title={ctaTitle}
					body={ctaBody}
					button={ctaButton}
					image={ctaImage}
				/>
				<ContactSection
					title={contactUsTitle}
					description={contactUsDescription}
					contactNumber={contactNum}
					contactEmail={contactEmail}
					facebookUrl={fbUrl}
					facebookLink={fbLink}
				/>
				</main>
			</Layout>
		</>
	);
};

export const query = graphql`
	query MyQuery {
		hero: allSanityHero {
			nodes {
				titleOne
				titleTwo
				subtitle
				image {
					...Image
				}
				primaryButton {
					link
					text
				}
				secondaryButton {
					link
					text
				}
			}
		}
		menuItems: allSanityMenuItems {
			nodes {
				menuItem
				menuLink
				sortOrder
			}
		}
		settings: allSanitySettings {
			nodes {
				facebook
				facebookUrl
				contactNumber
				contactEmail
				logo {
					...Image
				}
			}
		}
		services: allSanityServices {
			nodes {
				title
				description
				icon
			}
		}
		contactUs: allSanityContactUs {
			nodes {
				description
				title
			}
		}
		testimonialSection: allSanityTestimonials {
			nodes {
				testimonialAuthor
				testimonialText
				testimonialsTitle
				_rawBody
				body {
					_key
					_type
					style
					list
					_rawChildren
				}
				testimonialImage {
					...Image
				}
			}
		}
		cta: allSanityCallToAction {
			nodes {
				ctaButton {
					link
					text
				}
				ctaImage {
					...Image
				}
				ctaBody
				ctaTitle
			}
		}
	}
`;

export default IndexPage;
