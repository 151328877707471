import { Link } from "gatsby";
import React from "react";

const Button = ({
	type = "primary",
	children,
	link = "#",
	inline = false,
	isExternalLink = false,
}) => {
	const classes =
		type == "primary"
			? `text-white bg-yellow-500 hover:bg-yellow-600`
			: `text-yellow-700 bg-yellow-100 hover:bg-yellow-200 border-yellow-400`;

	if (inline) {
		if (isExternalLink) {
			return (
				<a
					href={link}
					target="_blank"
					className={`w-min whitespace-nowrap flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10 ${classes}`}
				>
					{children}
				</a>
			);
		}
		return (
			<Link
				to={link}
				className={`w-min whitespace-nowrap flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10 ${classes}`}
			>
				{children}
			</Link>
		);
	}
	if (isExternalLink) {
		<div className={`rounded-md shadow`}>
			<a
				href={link}
				target="_blank"
				className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10 ${classes}`}
			>
				{children}
			</a>
		</div>;
	}
	return (
		<div className={`rounded-md shadow`}>
			<Link
				to={link}
				className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10 ${classes}`}
			>
				{children}
			</Link>
		</div>
	);
};

export default Button;
