import React from "react";
import loadable from "@loadable/component";

const ServiceCard = ({ service: { title, description, icon } }) => {
	const Icon = loadable(() => import(`@heroicons/react/outline/${icon}Icon`));
	return (
		<div>
			<dt>
				<div className="flex items-center gap-4">
					<div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-600 text-white">
						<Icon className="h-6 w-6" />
					</div>
					<p className="mt-5 text-lg leading-6 font-medium text-gray-900">
						{title}
					</p>
				</div>
			</dt>
			<dd className="mt-2 text-base text-gray-500">{description}</dd>
		</div>
	);
};

export default ServiceCard;
