import React from "react";
import Image from "gatsby-plugin-sanity-image";
import Button from "./Button";
import Menu from "./Menu";

const Hero = ({
	title,
	subtitle,
	menuItems,
	primaryButton,
	secondaryButton,
	image,
	logo,
	contactNumber,
}) => {
	return (
		<div className="relative bg-white overflow-hidden">
			<div className="max-w-7xl mx-auto">
				<div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-[44rem] lg:w-full lg:pb-28 xl:pb-32">
					<svg
						className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
						fill="currentColor"
						viewBox="0 0 100 100"
						preserveAspectRatio="none"
						aria-hidden="true"
					>
						<polygon points="50,0 100,0 50,100 0,100" />
					</svg>
					<Menu items={menuItems} logo={logo} contactNumber={contactNumber} />
					<section className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
						<div className="sm:text-center lg:text-left">
							<h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
								{title[0] && <span className="block">{title[0]}</span>}
								{title[1] && (
									<span className="block text-yellow-500"> {title[1]}</span>
								)}
							</h1>
							<p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 leading-relaxed sm:leading-relaxed md:leading-relaxed lg:leading-relaxed">
								{subtitle}
							</p>
							<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
								<div className="rounded-md shadow">
									<Button link={primaryButton.link}>
										{primaryButton.text}
									</Button>
								</div>
								<div className="mt-3 sm:mt-0 sm:ml-3">
									<Button link={secondaryButton.link} type="secondary">
										{secondaryButton.text}
									</Button>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gray-200">
				<Image
					{...image}
					width={600}
					className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
					alt="Very Good Support Services"
				/>
			</div>
		</div>
	);
};

export default Hero;
