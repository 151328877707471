import React from "react";
import { PhoneIcon, MailIcon } from "@heroicons/react/outline";

const ContactSection = ({
	title,
	description,
	contactNumber,
	contactEmail,
	facebookUrl,
	facebookLink,
}) => {
	return (
		<div className="relative bg-white" id="contact">
			<div className="absolute inset-0 bg-gray-50">
				<div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
			</div>
			<div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
				<div className="bg-gray-50 pt-16 pb-0 md:py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
					<div className="max-w-lg mx-auto">
						<h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
							{title}
						</h2>
						<p className="mt-3 text-lg leading-6 text-gray-500">
							{description}
						</p>
						<dl className="mt-8 text-base text-gray-500">
							<a href={`tel:${contactNumber}`}>
								<div className="mt-6 text-gray-500 hover:text-indigo-500">
									<dt className="sr-only">Phone number</dt>
									<dd className="flex">
										<PhoneIcon className="flex-shrink-0 h-6 w-6" />
										<span className="ml-3">{contactNumber}</span>
									</dd>
								</div>
							</a>
							<a href={`mailto:${contactEmail}`}>
								<div className="mt-3 text-gray-500 hover:text-indigo-500">
									<dt className="sr-only">Email</dt>
									<dd className="flex">
										<MailIcon className="flex-shrink-0 h-6 w-6" />
										<span className="ml-3">{contactEmail}</span>
									</dd>
								</div>
							</a>
							<a href={facebookLink} target="_blank">
								<ul
									className="mt-6 flex space-x-6 ml-0 text-gray-500 hover:text-indigo-500"
									role="list"
								>
									<li className="flex">
										<span className="sr-only">Facebook</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="w-6 h-6"
											aria-hidden="true"
										>
											<path
												d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
												fill="currentColor"
											/>
										</svg>
										<span className="ml-3">/{facebookUrl}</span>
									</li>
								</ul>
							</a>
						</dl>
					</div>
				</div>
				<div className="bg-gray-50 pt-4 pb-16 md:py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
					<div className="max-w-lg mx-auto lg:max-w-none">
						<form
							method="post"
							name="contact"
							className="grid grid-cols-1 gap-y-6"
							data-netlify="true"
						>
							<input type="hidden" name="contact" value="contact" />
							<input type="hidden" name="bot-field" />
							<div>
								<label htmlFor="full_name" className="sr-only">
									Full name
								</label>
								<input
									type="text"
									name="full_name"
									id="full_name"
									autoComplete="name"
									className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									placeholder="Full name"
								/>
							</div>
							<div>
								<label htmlFor="email" className="sr-only">
									Email
								</label>
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									placeholder="Email"
								/>
							</div>
							<div>
								<label htmlFor="phone" className="sr-only">
									Phone
								</label>
								<input
									type="text"
									name="phone"
									id="phone"
									autoComplete="tel"
									className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									placeholder="Phone"
								/>
							</div>
							<div>
								<label htmlFor="message" className="sr-only">
									Message
								</label>
								<textarea
									id="message"
									name="message"
									rows="4"
									className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
									placeholder="Message"
								></textarea>
							</div>
							<div>
								<button
									type="submit"
									className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									Submit
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
